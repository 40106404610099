.posts {
    margin: auto;
    width: 700px;
}

.post {
    margin: auto;
    min-height: 100px;
    overflow: hidden;
    width: 600px;
    word-break: break-all;
}